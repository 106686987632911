.notFoundContainer {
  composes: global(mt-12);
  max-width: 720px;
  margin: 0 auto;
}

.notFoundContent {
  composes: global(mt-8);
}

.notFoundImage {
  composes: global(rounded) global(mb-2);
}

.borderLine {
  composes: global(mb-8);
  border-bottom: 1px solid #e1e1e1;
}

.notFoundText {
  p {
    display: block;
    margin: 0 0 15px 0;
    color: #444;
    font-weight: 300;
    line-height: 2;
  }

  a {
    color: #00b5e2;
    text-decoration: none;
  }
}
