@import '../RoadmapCell.module';

.cellDragging {
  max-width: 269px;
  opacity: 1;
  transform: rotate(6deg);
  padding: cssvar(spacing-xs);
  box-shadow: cssvar(primary-box-shadow);

  > div:nth-of-type(2) {
    &:after {
      display: none;
    }
  }
}
