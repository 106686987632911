@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.dropZone {
  overflow: visible;
  word-wrap: break-word;
  word-break: break-word;
}

.item {
  cursor: pointer;
  padding: cssvar(spacing-xs);

  > {
    div:nth-of-type(1) {
      display: inline-block;
      width: 100%;
    }
  }

  &:hover {
    box-shadow: cssvar(primary-box-shadow);

    > div:nth-of-type(2) {
      display: inline-block;
    }
  }
}

.isDraggingItem {
  opacity: 0;
}
