@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.quillDescription {
  width: 100%;
}

.descriptionField {
  height: 260px;
}

.boxFieldSet {
  div[class^='hcr-fieldset__item'] {
    margin-top: cssvar(spacing-xxs);
  }

  legend {
    @include show-as-h3();
    padding-bottom: 0;
  }
}
