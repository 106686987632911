@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: 1;
}

.boxContainer {
  @extend .container;
  align-items: center;
}

.heading {
  @extend .container;
  flex-direction: column;
  text-transform: capitalize;
}

.iconContainer {
  align-items: center;
}
