@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.boxContainer {
  margin-bottom: 0;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.row {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
}

.delIcon {
  margin-right: cssvar(spacing-xxs);
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    fill: #ff5700;
  }
}

.buttonContainer {
  composes: global(flex) global(flex-row);
  padding-top: 12px;
}
