.wrapper {
  position: fixed;
  width: 400px;
  bottom: 50px;
  right: 50px;

  @media print {
    position: relative;
    right: 0;
    width: 40%;
    display: inline-block;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media print {
    display: none;
  }
}

.heading {
  padding-top: 0;
}

.headerIcon {
  cursor: pointer;

  & + & {
    margin-left: 14px;
  }
}

.button {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  display: inline-block;
  min-width: 0;
  padding: 0;
  line-height: 0;
  height: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.149);

  &.visible {
    display: block;
  }

  @media print {
    display: none !important;
  }
}

.buttonImg {
  display: inline-block;
  vertical-align: middle;
  padding: 14px;
}

.box {
  transform: translateY(100px);
  opacity: 0;
  pointer-events: none;
  transition: transform 0.25s ease-in, opacity 0.2s ease-in-out;
  margin: 0;

  &.visible {
    pointer-events: all;
    transform: translateY(0);
    opacity: 1;
  }

  @media print {
    box-shadow: none;
    border: none;
    pointer-events: all;
    transform: none;
    opacity: 1;
    padding: 0;
  }
}

.progressBar {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 10px;
  border-radius: 6px;
  margin-top: 3px;
}

.nameWrapper {
  flex: 3;
  margin-right: 12px;
  margin-bottom: -2px;
}

.name {
  margin-left: 6px;
}

.progress {
  flex: 2;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 12px;
  line-height: 9px;
  margin-bottom: 4px;
}

.progressVal {
  flex: 1;

  &.right {
    text-align: right;
  }
}

.progressCenter {
  flex: 3;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  align-items: center;
  margin-top: 10px;

  @media print {
    margin-top: 4px;
    padding-top: 6px;
    border-top: 1px solid #e1e1e1;

    &:first-of-type {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }
  }
}
