@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.headContainer {
  border-bottom: 1px solid cssvar(primary-line-color);
  padding-top: cssvar(spacing-sm);
  background-color: cssvar(primary-bg-color);

  &.sticky {
    width: 100%;
  }

  @media print {
    border-bottom: none;
  }
}

.printInformation {
  display: none;

  @media print {
    display: inline-block;
  }
}

.sticky {
  position: fixed;
  z-index: 1000;
  top: 0;

  @media print {
    position: relative;
  }
}

.normal {
  position: relative;
}

.extraSize {
  margin-top: 135px;

  @media print {
    margin-top: 0;
  }
}

.roadmapContainer {
  width: 100%;
  margin-top: cssvar(spacing-xs);
}
