.boxContainer {
  margin-bottom: 0;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  &:not(:first-of-type) {
    padding-top: 24px;
  }
}

.row {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 3px 0;
}

.delIcon {
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    fill: #ff5700;
  }
}

.peopleSearchBox {
  max-height: 250px;
  width: 100%;
  overflow-y: scroll;
  padding: 0;
}

.peopleSearchList {
  padding: 12px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.peopleSearchItem {
  padding: 6px 12px;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
  text-transform: capitalize;
  color: #444444;
}

.peopleEmail {
  cursor: pointer;
  text-transform: capitalize;
  color: #444444;
}
