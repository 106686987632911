@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.filterContainer {
  padding-bottom: cssvar(spacing-sm);

  div:after {
    top: 5px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  margin-bottom: cssvar(spacing-xs);

  @include on-bp(md) {
    margin-bottom: 0;
  }
}

.switchItem {
  @media (min-width: 768px) {
    display: inherit !important;
  }

  > div:first-of-type:after {
    top: 50%;
  }
}

@media print {
  .filterContainer {
    display: none;
  }
}
