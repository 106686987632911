@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.dependenciesWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dependencyWrapper {
  width: 100%;

  hr {
    margin: cssvar(spacing-md) 0;
  }
}

.deleteDependency {
  align-self: flex-start;
}

.epicLinkField {
  margin-top: cssvar(spacing-sm);
}
