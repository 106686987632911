
.Page {
  composes: global(flex) global(flex-col) global(flex-1) global(my-8) global(w-full);
  max-width: 935px;
  padding: 0 15px;
}

:global(body) {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global(textarea) {
  resize: none !important;
  min-height: 170px;
}

:global(#_hj_feedback_container) {
  @media print {
    display: none;
  }
}

:global(.ql-toolbar.ql-snow) {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-color: #c8c8c8;
}

:global(.ql-container.ql-snow) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #c8c8c8;
}

:global(.ql-editor) {
  min-height: 170px;
}

:global(select) {
  text-transform: capitalize !important;
}
