@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.popupBody {
  > div:first-child {
    width: 635px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

[class*='--valid-']::before {
  background: none;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.buttonsRow {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonsControl {
  text-align: center;

  button {
    margin: 0;
    padding: 0;
  }
}
