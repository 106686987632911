@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.panelTitle {
  color: cssvar(primary-bg-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jiraLink {
  margin-left: cssvar(spacing-xs);
}

.panelTable {
  h3 {
    padding: cssvar(spacing-smxs) 0 cssvar(spacing-xxs);
  }

  table {
    td {
      &:first-of-type {
        font-weight: bold;
      }

      &:last-of-type {
        text-transform: capitalize;
      }
    }
  }
}

.notes {
  p:empty {
    display: none;
  }

  br:only-child {
    display: none;
  }
}

.tag {
  margin-right: cssvar(spacing-xxs);
}
