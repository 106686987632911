@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.content {
  composes: global(block) global(justify-between) global(items-start) global(font-bold);
}

.title {
  composes: global(flex-1) global(inline-block);
  width: 70%;
}

.teamName {
  composes: global(block) global(mb-2) global(uppercase) global(font-bold);

  color: #757575;
  font-size: 14px;
  line-height: 16px;
}

.hintContainer {
  composes: global(relative) global(float-right);

  margin: 0 0 0 6px;
}

.hint {
  composes: global(font-light) global(text-left);
  font-size: 16px;

  b {
    font-weight: bold;
  }

  &.success {
    b {
      color: #5bac00;
    }
  }
  &.default {
    b {
      color: #747474;
    }
  }
  &.warning {
    b {
      color: #c68601;
    }
  }
  &.error {
    b {
      color: #c64302;
    }
  }
}

.nameHint {
  composes: global(font-light) global(inline-block) global(static);
  composes: global(capitalize);
}

.shirt {
  composes: global(font-normal) global(rounded-full) global(inline-block) global(align-middle) global(text-center) global(float-right);

  width: 24px;
  height: 24px;
  color: #000000;
  background: #f7f7f7;
  font-size: 14px;
}

.hintIcon {
  composes: global(align-middle) global(inline-block);

  margin: 0;
  width: 24px;
  height: 24px;
  max-width: 24px;
  pointer-events: none;

  title {
    display: none;
  }
}

.description {
  composes: global(flex) global(flex-1) global(mt-2);

  p {
    width: 235px;
    height: auto;
    max-height: 130px;
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    line-clamp: 6;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
  }
}

.assignees {
  padding: 10px 0 0 0;
}

.assigneeBullet {
  composes: global(mr-2) global(rounded-full) global(text-sm) global(font-medium) global(uppercase);

  background: #f7f7f7;
  border: 1px solid #e1e1e1;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  color: #444444;
}

.milestones {
  composes: global(list-reset) global(mt-1) global(mb-0) global(flex) global(flex-wrap) global(justify-between);

  li {
    display: flex;
    flex: 1 1 100%;
    justify-content: left;
    align-items: flex-start;

    img[alt='not-concluded'] {
      margin: 1px 5px 0 -1px;
      height: 18px;
      width: 18px;
    }

    img[alt='concluded'] {
      margin: 1px 5px 0 0;
      height: 16px;
      width: 16px;
    }
  }
}

.tags {
  margin-top: cssvar(spacing-xxs);
}

.tag {
  max-width: 115px;
  margin-right: cssvar(spacing-xxs);

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.progress {
  composes: global(mb-2);

  position: relative;
  display: flex;
  justify-content: space-between;
}

.pill {
  flex: 0.24;
  height: 5px;
  background-color: #e1e1e1;
  z-index: 0;
  margin-right: 3px;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin-right: 0;
  }
}

.activePill {
  background-color: #73d700;
}
