.boxContainer {
  margin-bottom: 0;
}

.container {
  composes: global(flex) global(flex-row) global(justify-between) global(flex-wrap);
  border-bottom: 1px solid #c8c8c8;

  &:not(:first-of-type) {
    padding-top: 24px;
  }
}

.row {
  composes: global(flex) global(flex-row) global(items-center);
}

.delIcon {
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    fill: #ff5700;
  }
}

.buttonContainer {
  composes: global(flex) global(flex-row);
  padding-top: 12px;
}
