@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: cssvar(secondary-bg-color);
}

.pageContainer {
  flex: 1;
  width: 100%;
}
