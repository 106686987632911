@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.priorities {
  div[class^="hcr-col-"] {
    min-height: cssvar(spacing-lg);
    text-align: center;
    border: solid cssvar(primary-line-color);
    border-width: 1px 1px 0 0;
    margin: 0;

    &:first-child {
      border-left-width: 1px;
      border-top-left-radius: cssvar(primary-border-radius);
    }

    &:last-child {
      border-top-right-radius: cssvar(primary-border-radius);

      h3 {
        color: cssvar(secondary-content-color);
      }
    }

    @media print {
      min-height: cssvar(spacing-sm);
      border: none;
    }
  }

  @media print {
    clear: both;
    border-bottom: 1px solid cssvar(primary-line-color);
  }
}
