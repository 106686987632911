@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.table {
  background-color: cssvar(primary-bg-color);
  td:last-child {
    min-width: 200px;
  }
}

.overflowContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
}

.textContainer {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
