.boxContainer {
  margin-bottom: 0;
}

.container {
  composes: global(flex) global(flex-row) global(justify-between) global(flex-wrap);
}

.row {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.delIcon {
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    fill: #ff5700;
  }
}

.buttonContainer {
  composes: global(flex) global(flex-row);
  padding-top: 12px;
}
